import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { LayerDisabled, SizeRulerBox, SizeRulerImage } from "../styled"
import { dropLangOptions } from "../../../utils/langDropHelper"

const languages = dropLangOptions()

const ProductSizeDetailWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "GothamLight";
  font-weight: bold;

  @media (max-width: 600px) {
    display: ${({ mobile }) => (mobile ? "block" : "none")};
  }

  @media (max-width: 420px) {
    font-size: 12px;
  }
`

const Text = styled.div`
  display: inline;
`

const FindMoreText = styled.div`
  text-decoration: underline;
  display: ${({ findMore }) => (findMore ? "none" : "block")};
  cursor: pointer;
`

const SizePopupButton = styled.div`
  position: relative;
  text-align: right;
  display: none;
  > span {
    text-decoration: underline;
  }
`

export const ProductSizeDetail = ({
  intl,
  data,
  openSizeModal,
  disabled,
  mobile,
  findMore,
}) => (
  <ProductSizeDetailWrapper mobile={mobile}>
    {disabled && <LayerDisabled />}
    <Text>{intl.formatMessage({ id: "size_measure_text" })}</Text>
    {intl.locale === "fr" || intl.locale === "en" ? (
      <SizeRulerBox>
        <SizeRulerImage
          className="lazyload"
          data-src="https://static.percko.com/uploads/f3c4a6f1-3704-48e1-9668-b19de8889d47.svg"
        />
        <FindMoreText findMore={findMore} onClick={() => openSizeModal(true)}>
          {intl.formatMessage({ id: "find_more_size" })}
        </FindMoreText>
      </SizeRulerBox>
    ) : (
      <FindMoreText findMore={findMore} onClick={() => openSizeModal(true)}>
        {intl.formatMessage({ id: "find_more_size" })}
      </FindMoreText>
    )}
    {intl.locale == "en" && (
      <SizePopupButton>
        <img
          style={{ marginBottom: 0, marginRight: 5 }}
          className="lazyload"
          data-src={
            languages.find(element => element.locale === intl.locale).flag
          }
        />
        <span>{intl.formatMessage({ id: "size_chart_text" })}</span>
      </SizePopupButton>
    )}
  </ProductSizeDetailWrapper>
)

export default injectIntl(ProductSizeDetail)
